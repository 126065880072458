import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Select, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import _ from 'lodash';
import dayjs from 'dayjs';

import { COPY_MESSAGE, MAX_FIELD_PER_ROW } from '../../constants';
import { checkRegexInValidation, showErrorMessage, showSuccessMessage } from '../../utils';
import { postData } from '../../services/HomeService';
import EditableLabel from './EditableLabel';

export default function FormRegistration({ formIndex = -1, formTemplateData = {},disabledSections={}, setDisabledFields, setDisabledSections ,disabledFields = {}, onFieldChange, formPrefillData, formStyle = {},  formFormatLong = true, formDataValidation, partnerID }) {
    const [fieldsData, setFieldsData] = useState({});
    const [isEditing, setIsEditing] = useState(null); 
    const [alreadyPrefilled, setAlreadyPrefilled] = useState(false);
    useEffect(() => {
        const fieldKeys = formTemplateData ? Object.keys(formTemplateData) : [];
        fieldKeys.forEach((key) => formTemplateData[key]['fieldKey'] = key);
        const tempFieldsData = _.groupBy(formTemplateData, 'fieldSection')
        if(fieldKeys.length > 0) setFieldsData(tempFieldsData);
        if (formPrefillData && !alreadyPrefilled) {
            console.log("prefilled ");
            Object.keys(formTemplateData).forEach((key) => {
                const field = formTemplateData[key];
                if (formPrefillData.sections?.[field?.fieldSection]) {
                    const { currentField } = getFieldData(field); 
                    setAlreadyPrefilled(true);
                    if (currentField) {
                        field.fieldValue = currentField.fieldValue;
                        field.fieldLabel = currentField.fieldLabel;
                        updateDependencies(field.fieldSection, field.fieldKey, currentField.fieldValue, _.cloneDeep(tempFieldsData));
                    }
                }
            });
            onFieldChange(formIndex, formTemplateData);
        }
    }, [ formPrefillData, formIndex, formTemplateData, onFieldChange]);


 const updateDependencies = (section, fieldKey, value, tempFieldsData) => {
    let fieldsToUpdate = {};
    let sectionsToUpdate = {};
    const dependencies = tempFieldsData[section]?.find(f => f.fieldKey === fieldKey)?.dependencies || [];
    
    dependencies.forEach(dependency => {
        let targetIndexes = [];
        let targetSection = section;

        if (value === "" || value=== null) return

        if (dependency.type === 'field') {
            // Search across all sections
            Object.keys(tempFieldsData).forEach(sec => {
                const index = _.findIndex(tempFieldsData[sec], { fieldKey: dependency.key });
                if (index !== -1) {
                    targetIndexes.push(index);
                    targetSection = sec; // Update the target section to the one where the field is found
                }
            });
        } else if (dependency.type === 'section') {
            targetIndexes = _.map(tempFieldsData[dependency.key], (_, index) => index);
            targetSection = dependency.key;
        }

        const isDependencyMet = dependency.value.includes(value.toLowerCase());

        const actionKey = isDependencyMet ? 'positiveAction' : 'negativeAction';
        const actions = dependency[actionKey];

        targetIndexes.forEach(index => {
            if (index === -1) return;

            const targetField = tempFieldsData[targetSection][index];
            
            if (!targetField) return;
            if(targetField.fieldKey === fieldKey) return;
            if (actions.includes('disabled')) {
                tempFieldsData[targetSection][index].fieldValue = '';
                fieldsToUpdate[targetField.fieldKey] = true; // Track updates with section.fieldKey format
            }

            if (actions.includes('mandatory')) {
                fieldsToUpdate[targetField.fieldKey] = false;
            }
            if(actions.includes('hide')){
                sectionsToUpdate[targetField.fieldKey] = true;
            }
            if(actions.includes('show')){
                sectionsToUpdate[targetField.fieldKey] = false;
            }
        });
    });

     // Apply collected updates
     if (Object.keys(fieldsToUpdate).length > 0) {

        setDisabledFields(formIndex, { ...disabledFields, ...fieldsToUpdate });
    }
    if (Object.keys(sectionsToUpdate).length > 0) {
        setDisabledSections(formIndex, { ...disabledSections, ...sectionsToUpdate });
    }
};

    const handleInputChange = (section, fieldKey, value) => {
        const tempFieldsData = _.cloneDeep(fieldsData);
        const fieldIndex = _.findIndex(tempFieldsData[section], { fieldKey });
        if (fieldIndex !== -1) {
            // Update field value
            tempFieldsData[section][fieldIndex].fieldValue = value;
        }
        updateDependencies(section, fieldKey, value, tempFieldsData);
        formTemplateData[fieldKey].fieldValue = value;
        setFieldsData(tempFieldsData);
        onFieldChange(formIndex, formTemplateData);
    };

    const handleCopyClick = (value) => {
        if (value) {
            navigator.clipboard.writeText(value);
            message.success(COPY_MESSAGE, 1.5);
        }
    };

    const handleEditClick = (key) => {
        setIsEditing(key); 
    };

    const getFieldData = (fieldData) => {
        const currentField = formPrefillData?.sections?.[fieldData.fieldSection]?.find(f => f.fieldKey === fieldData.fieldKey);
        const fieldStatus = currentField?.fieldStatus;
        const value = currentField?.fieldValue;
        const fieldStyle = currentField?.fieldStyle
        return { currentField, fieldStatus, value, fieldStyle };
    };
    
    const handleSaveType = async (fieldData) => {
        if (_.isEmpty(partnerID)) return;

        const requestData = {
            pid: partnerID,
            fieldKey: fieldData.fieldKey,
            fieldLabel: fieldData.fieldLabel
          };
        const response = await postData("/partners/custom-fields", requestData);
        if(response.success){
            setIsEditing(null);
            showSuccessMessage('Label updated successfully');
        } else {
            showErrorMessage('Failed to update label');
        } 
    };

  const handleFieldLabelChange = (e, fieldKey) => {
        const { value } = e.target;
        setFieldsData(prevSections => {
            const updatedSections = _.cloneDeep(prevSections);
            _.forEach(updatedSections, section => {
                const fieldData = section.find(field => field.fieldKey === fieldKey);
                if (fieldData) {
                    fieldData.fieldLabel = value;
                    return false;
                }
            });
            return updatedSections;
        });
    };

    const renderFieldLabelAndEditButton = (fieldData) => {
        const { currentField } = getFieldData(fieldData);
        return (
            <div className={isEditing === fieldData.fieldKey ? 'ProfileView_subTitleEditing' : 'ProfileView_subTitle vform'}>
               <EditableLabel
                    value={currentField}
                    data={fieldData}
                    isEditing={isEditing}
                    handleFieldLabelChange={handleFieldLabelChange}
                    handleSaveType={handleSaveType}
                    handleEditClick={handleEditClick}
                    formPrefillData={formPrefillData}
                    title='Value is required before updating label'
                    type='EditModal'
                />
            </div>
        );
    };
    
    const renderFieldsInRow = (fields, section) => {
        let fieldsToRender = fields;
        if (!formFormatLong) {
            fieldsToRender = fields.slice(0, MAX_FIELD_PER_ROW);
        }
        return fieldsToRender.reduce((rows, fieldData, index) => {
            if (index % MAX_FIELD_PER_ROW === 0) {
                rows.push(
                    <div className='Registration_input' key={`${section}-${index}`}>
                        {fieldsToRender.slice(index, index + MAX_FIELD_PER_ROW).map((field) => (
                            <div className='Registration_inputField' key={`${section}-${field.fieldKey}`} style={{ maxWidth: formStyle.maxWidth, width: formStyle.width }}>
                                {renderField(field, section)}
                            </div>
                        ))}
                    </div>
                );
            }
            return rows;
        }, []);
    };

    const renderErrorMessage = (fieldData, isFieldValueEmpty, isFieldValueInvalid, isFieldValueUnVerified, isVerificationPending, isDisabled, isHidden) => {
        if(isDisabled || isHidden) return '';
        switch (true) {
            case isFieldValueEmpty:
                return `Please Enter ${fieldData.fieldLabel}`;
            case isFieldValueInvalid:
                return `Please Enter Valid ${fieldData.fieldLabel}`;
            case isVerificationPending:
                return `${fieldData.fieldLabel} verification is pending.`; 
            case isFieldValueUnVerified:     
                return `${fieldData.fieldLabel} verification has failed. Please retry`; 
            default:
                return '';
        }
    };
   

    const renderField = (fieldData, section) => {
        const { fieldStatus, fieldStyle } = getFieldData(fieldData);
        const isFieldValueEmpty = Boolean(formDataValidation && fieldData.fieldStyle?.showMandatoryMarker && !(fieldData.fieldValue && fieldData.fieldValue.trim()));
        const isFieldValueInvalid =  Boolean(formDataValidation && (fieldData.fieldValidator && fieldData.fieldValue) && checkRegexInValidation({[fieldData.fieldKey]: fieldData}, disabledFields, disabledSections));
        const isFieldValueUnVerified = Boolean(fieldData.fieldValue && fieldData.fieldStyle?.showVerification && fieldStatus && fieldStatus?.verification === 'Invalid');
        const isVerificationPending = fieldData.fieldValue && fieldData.fieldStyle?.showVerification && fieldStatus && fieldStatus.verification === 'Pending';
        const isUpdateNotAllowed = fieldStyle?.updateNotAllowed && fieldData.fieldValue && fieldStatus?.validation;
        const isDisabled = disabledFields[fieldData.fieldKey] === undefined? fieldStyle?.disabled : disabledFields[fieldData.fieldKey];
        const isHidden = disabledSections[fieldData.fieldKey] === undefined ? fieldStyle?.hide : disabledSections[fieldData.fieldKey];
        const alwaysDisabled = fieldData.fieldStyle?.alwaysDisabled;
        let className = '';
        if (isFieldValueEmpty || isFieldValueInvalid || isFieldValueUnVerified) {
            className = 'mandatoryEmpty';
        } else if (isVerificationPending) {
            className = 'mandatoryPending';
        }
        if(isDisabled){
            className = '';
        }
        if(!isHidden){
            if (fieldData.fieldType === 'DropDown') {
            return (
                <>
                <div className={`Registration_inputField ${fieldData.fieldValue ? 'hasValue' : ''} ${className}`} style={{ width: '100%' }} key={fieldData.fieldKey}>
                    <div className='point'>{fieldData.fieldStyle?.showMandatoryMarker && !isDisabled && <span style={{ color: 'red' }}>*</span>}</div>
                    {fieldData.fieldValue && renderFieldLabelAndEditButton(fieldData)}

                    <Select
                        key={`subs-${fieldData.fieldKey}`}
                        placeholder={fieldData.fieldLabel}
                        value={fieldData.fieldValue || undefined}
                        onChange={(value) => handleInputChange(section, fieldData.fieldKey, value)}
                        disabled={alwaysDisabled || isDisabled || isUpdateNotAllowed} 
                    >
                        {fieldData.fieldOptions.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <p className={isVerificationPending ? 'v_yellow' : 'v_red'}>{renderErrorMessage(fieldData, isFieldValueEmpty, isFieldValueInvalid , isFieldValueUnVerified, isVerificationPending, isDisabled, isHidden)}</p>
                </>
            );
            } else if (fieldData.fieldType === 'DateTime') {
                return (
                    <>
                    <div className={`Registration_inputField ${fieldData.fieldValue ? 'hasValue' : ''} ${className}`} style={{ width: '100%' }} key={fieldData.fieldKey}>
                        <div className='point'>{fieldData.fieldStyle?.showMandatoryMarker && !isDisabled && <span style={{ color: 'red' }}>*</span>}</div>
                        {fieldData.fieldValue && renderFieldLabelAndEditButton(fieldData)}

                        <DatePicker
                            key={`subs-${fieldData.fieldKey}`}
                            showNow
                            placeholder={fieldData.fieldLabel}
                            value={fieldData.fieldValue? dayjs(fieldData.fieldValue): null}
                            onChange={(_date, dateString) => handleInputChange(section, fieldData.fieldKey, dateString)}
                            disabled={alwaysDisabled || isDisabled || isUpdateNotAllowed}
                            format="YYYY-MM-DD"
                        />
                    </div>
                    <p className={isVerificationPending ? 'v_yellow' : 'v_red'}>{renderErrorMessage(fieldData, isFieldValueEmpty, isFieldValueInvalid , isFieldValueUnVerified, isVerificationPending, isDisabled, isHidden)}</p>
                    </>
                );
            } else {
                return ( <>

                    <div className={`Registration_inputField ${fieldData.fieldValue ? 'hasValue' : ''} ${className}`} style={{ width: '100%' }} key={fieldData.fieldKey}>
                        <div className='point'>{fieldData.fieldStyle?.showMandatoryMarker  && !isDisabled && <span style={{ color: 'red', fontSize: '12px' }}>*</span>}</div>
                        {fieldData.fieldValue && renderFieldLabelAndEditButton(fieldData)}

                        <Input
                            key={`subs-${fieldData.fieldKey}`}
                            placeholder={fieldData.fieldLabel}
                            value={fieldData.fieldValue}
                            onChange={(e) => handleInputChange(section, fieldData.fieldKey, e.target.value)}
                            disabled={alwaysDisabled || isDisabled || isUpdateNotAllowed}
                            addonAfter={fieldData.fieldStyle?.showCopyOutlinedIcon && (
                                <CopyOutlined onClick={() => handleCopyClick(fieldData.fieldValue)} />
                            )}
                        />
                    </div>
                    <p className={isVerificationPending ? 'v_yellow' : 'v_red'}>{renderErrorMessage(fieldData, isFieldValueEmpty, isFieldValueInvalid , isFieldValueUnVerified, isVerificationPending, isDisabled, isHidden)}</p>
                    </>
                );
            }
        }
        else{
            return <></>
        }
    };

    return (
        <div className='Registration_secondBox' style={{ padding: formStyle.padding }}>
            {_.keys(fieldsData).map((section) => (
                <>
                    <div className='Registration_title'>
                        <label>{section}</label>
                    </div>
                    {renderFieldsInRow(fieldsData[section], section)}
                </>
            ))}
        </div>
    );
}
