import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, Modal, Switch, message } from 'antd';
import { useSelector } from "react-redux";
import _ from 'lodash'

import FormRegistration from '../../common/FormRegistration';
import { insertApiCall, formTemplate } from '../../../services/RegistrationService';
import { SPIN_TEXT_MAP } from '../../../constants';
import { fetchData } from '../../../services/HomeService';
import { checkEmptyFields, checkRegexInValidation, parseToListObj, showErrorMessage, showLoader, formatCleanData } from '../../../utils';
import RequestNudgeComponent from '../../nudge/RequestNudgeComponent';



export default function RegistrationComponent({ prefillmap, template, registerType='vendor', isBulkUpload=false, formname , minimal=false}) {
    const FORM_TEMPLATE_OBJECT = {}
    const SOURCE_ENTITY = 'partners';
    if (!_.isEmpty(template)) _.assign(FORM_TEMPLATE_OBJECT, template);
    const [formTemplateObject, setFormTemplateObject] = useState(FORM_TEMPLATE_OBJECT);
    const [formTemplateData, setFormTemplateData] = useState(!_.isEmpty(FORM_TEMPLATE_OBJECT) ? [_.cloneDeep(FORM_TEMPLATE_OBJECT)] : []);
    const [saveStatus, setSaveStatus] = useState({ "-1": false });
    const [showProcessingMessage, setShowProcessingMessage] = useState(false);
    const [disabledFields, setDisabledFields] = useState([]);
    const [disabledSections, setDisabledSections] = useState([]);

    const [isWarningModal, setIsWarningModal] = useState(false);
    const [isWarningModalData, setWarningModalData] = useState();
    const [isFormDataValidated, setIsFormDataValidated]= useState(false);
    const [detailedFormSwitchChecked, setDetailedFormSwitchChecked] = useState(true);
    const [dataValidationSwitchChecked, setDataValidationSwitchChecked] = useState(!isBulkUpload);

    const userData = useSelector((state) => state.user); 

    useEffect(() => {
        fetchTemplate()
        setIsFormDataValidated(false)
    }, [template, formname]);
    useEffect(() => {
        if (!_.isEmpty(formTemplateObject)) {
            setFormTemplateData([_.cloneDeep(formTemplateObject)]);
        }
    }, [ formTemplateObject]);
    useEffect(() => {
        const tempFieldsObj = [];
        const tempStatusObj = {};
        const parsedCSVData = parseToListObj(prefillmap);
        for (const index in parsedCSVData) {
            tempStatusObj[index] = false;
            const row = parsedCSVData[index];
            const tempFieldObj = _.cloneDeep(formTemplateObject);
    
            for (const key of _.keys(row)) {
                
                if (formTemplateData[index]) {
                    const bypassCleaning = formTemplateData[index][key] && formTemplateData[index][key]['fieldStyle'] && formTemplateData[index][key]['fieldStyle']['bypassCleaning'];
                    const rowKeyData = !bypassCleaning? formatCleanData(row[key]) : row[key];
                    const field = formTemplateData[index][key];
                    if (field) {
                        if (field.fieldType === 'DropDown') {
                            const matchingOption = field.fieldOptions.find(option => option?.value.toLowerCase() === rowKeyData.toLowerCase() || option?.label.toLowerCase() === rowKeyData.toLowerCase());
                            if (matchingOption) {
                                formTemplateData[index][key]['fieldValue'] = matchingOption.label;
                            }
                        } else {
                            formTemplateData[index][key]['fieldValue'] = rowKeyData;
                        }
                        if(field.fieldStyle && field.fieldStyle.alwaysDisabled){
                            formTemplateData[index][key]['fieldValue'] = '';
                        }
                    }
                } else {
                    const bypassCleaning = tempFieldObj[key] && tempFieldObj[key]['fieldStyle'] && tempFieldObj[key]['fieldStyle']['bypassCleaning'];
                    const rowKeyData = !bypassCleaning? formatCleanData(row[key]) : row[key];
                    const field = tempFieldObj[key];
                    if (field) {
                        if (field.fieldType === 'DropDown') {
                            const matchingOption = field.fieldOptions.find(option => option?.value.toLowerCase() === rowKeyData.toLowerCase() || option?.label.toLowerCase() === rowKeyData.toLowerCase());
                            if (matchingOption) {
                                tempFieldObj[key]['fieldValue'] = matchingOption.label;
                            }
                        } else {
                            tempFieldObj[key]['fieldValue'] = rowKeyData;
                        }
                        if(field.fieldStyle && field.fieldStyle.alwaysDisabled){
                            tempFieldObj[key]['fieldValue'] = '';
                        }
                    }
                }
            }
            if (!formTemplateData[index]) {
                tempFieldsObj.push(tempFieldObj);
            }
        }
        let overallData = [...formTemplateData, ...tempFieldsObj];
        //todo: check dependencies
        if(prefillmap){

            for(const index in overallData){
                const formData = overallData[index];
                for(const fieldKey in formData){
                    const field = formData[fieldKey];
                    if(field.dependencies){
                        updateDependencies(field.dependencies, field, formData, index)
                    }
                }
            }
        }
        if(formTemplateData.length > 0){
            setFormTemplateData([...formTemplateData, ...tempFieldsObj]);
            setSaveStatus({ ...saveStatus, ...tempStatusObj });
        }
        
    }, [prefillmap, formTemplateObject]);
    const updateDependencies = (dependecies, field, formData, formIndex)=>{
        let fieldsToUpdate = {};
        let sectionsToUpdate = {};
        
        for(const dependency of dependecies){
            let targetKeys = [];
            if (field.fieldValue === "" || field.fieldValue === null) {
                continue;
            }
            const isDependencyMet = dependency.value.includes(field.fieldValue?.toLowerCase());
            const actionKey = isDependencyMet ? 'positiveAction' : 'negativeAction';
            const actions = dependency[actionKey];

           if(dependency.type == 'section'){
                for(const fieldKey in formData){
                    const localField = formData[fieldKey];
                    if(localField.fieldSection == dependency.key && localField.fieldKey != field.fieldKey){
                        targetKeys.push(fieldKey)
                    }
                }
           }
           else if(dependency.type == 'field'){
                targetKeys.push(dependency.key)
           }
            for(const key of targetKeys){
            const targetField = formData[key];
            if(targetField){
                if (actions.includes('disabled')) {
                    formData[key].fieldValue = '';
                    fieldsToUpdate[key] = true;
                }
    
                if (actions.includes('mandatory')) {
                    fieldsToUpdate[key] = false;
                }
                if(actions.includes('hide')){
                    sectionsToUpdate[key] = true;
                }
                if(actions.includes('show')){
                    sectionsToUpdate[key] = false;
                } 
            }
            }
           
        }
        // Apply collected updates
        if (Object.keys(fieldsToUpdate).length > 0) {
            disabledFields[formIndex] = { ...disabledFields[formIndex], ...fieldsToUpdate }
            setDisabledFields(disabledFields)
        }
        if (Object.keys(sectionsToUpdate).length > 0) {
            disabledSections[formIndex] = { ...disabledSections[formIndex], ...sectionsToUpdate }
            setDisabledSections(disabledSections)
        }

    }
    const fetchTemplate = () => {
        const templateData = {}
            fetchData(`/partners/register/${registerType}?formname=${formname}`)
                .then((resp) => {
                    _.assign(templateData, resp.data)
                    setFormTemplateObject(templateData);
                }).catch((err) => {
                    console.log(err)
                })
    };

    const addNewForm = () => {
        setFormTemplateData([...formTemplateData, _.cloneDeep(formTemplateObject)])
    };

    const makeApiCallAndSetStatus = async (requestData, index) => {
        try {
            setShowProcessingMessage(true);
            const { success, id } = await insertApiCall({requestData, registerType, dataValidationSwitchChecked});
            if (success && id) {
                setSaveStatus((prevStatus) => ({ ...prevStatus, [index]: true }));
                setShowProcessingMessage(false);
                setTimeout(() => {
                    setShowProcessingMessage(false);
                    message.success({
                        content: SPIN_TEXT_MAP['VENDOR_RESGISTRATION_SUCCESS'],
                        duration: 2,
                    });
                }, 2000);
            } else {
                setTimeout(() => {
                    setShowProcessingMessage(false);
                    message.error({
                        content: SPIN_TEXT_MAP['VENDOR_RESGISTRATION_ERROR'],
                        duration: 2,
                    });
                }, 2000);
            }
        } catch (error) {
            setShowProcessingMessage(false);
        }
    };

    const handleInputFieldChange = async (index, formData) => {
        formTemplateData[index] = formData
        setFormTemplateData(formTemplateData)
        setSaveStatus({ ...saveStatus, [index]: false })
        
    };
    const handleDisabledFields = (index, fields) => {
        setDisabledFields({ ...disabledFields, [index]: fields })
    };
    const handleDisabledSections = (index, section) => {
        setDisabledSections({ ...disabledSections, [index]: section })
    };


    const openWarningModal = (index=-1) => {
        setIsWarningModal(true);
        setWarningModalData(index);
     }
    
    const closeWarningModal = async () => {
        setIsWarningModal(false);
        setWarningModalData('');
    };

   const makeApiCallAndUpdateStatus = async (index) => {

    const requestData = Object.entries(formTemplateData[index]).reduce((acc, [key, value]) => {
        if (value.fieldStyle && value.fieldStyle.bypassCleaning) {
            acc[key] = value.fieldValue;
        } else {
            acc[key] = formatCleanData(value.fieldValue);
        }
        return acc;
    }, {});
    const requestPayload = await formTemplate(requestData, SOURCE_ENTITY, `Vendor ${index + 1}`, _.get(userData, 'org', ''));
    await makeApiCallAndSetStatus({...requestPayload, formname}, index);
};

    const handleSaveAll = async () => {
        if (!dataValidationSwitchChecked) {
            if (!isWarningModal){
                openWarningModal();
                return
            }
        }
        for (const index in formTemplateData) {
            await handleSave(index, false);
        }
    }
    const handleSave = async (index, alertBox = true) => {
        if (index < 0) return

        if (!dataValidationSwitchChecked) {
            if (!isWarningModal){
                openWarningModal(index);
                return
            }
        } else{
            if (checkEmptyFields(formTemplateData[index], disabledFields[index], disabledSections[index])) {

                setIsFormDataValidated(true); 
                showErrorMessage('Some required fields are missing')
                return;
            }

            if (checkRegexInValidation(formTemplateData[index], disabledFields[index], disabledSections[index])) {
                setIsFormDataValidated(true);
                showErrorMessage('Some fields have invalid inputs')
                return
            }
            setIsFormDataValidated(false);
        }

        if (saveStatus[index] === false) {
            if (!_.get(userData, 'org', '')) {
                showErrorMessage('Please add your account information before onboarding new vendors')
                return;
            }
            await handleProceed(index, isFormDataValidated && dataValidationSwitchChecked);
        } else if (alertBox) {
            showErrorMessage("This form is already saved or non edited");
        }
    };

    const handleProceed = async (index, skip=true) => {
        if(!skip){
            await makeApiCallAndUpdateStatus(index);
        } else {
            showErrorMessage('Please complete all required fields')
        }
    };

    const handleSubmitWarning = async (index) => {
        if(index < 0 ) await handleSaveAll();
        else await handleSave(index);
        await closeWarningModal();
    }

    return (
        <main className='Registration_main'>
            
        {!minimal && <section className='Toggler_main'>
            <div className='Toggler_containerLeft'>
            {/* <span>Detailed Form <Switch checked={detailedFormSwitchChecked} onChange={(checked) => setDetailedFormSwitchChecked(checked)} /></span> */}
                <span>Data Validation <Switch defaultChecked={!isBulkUpload}  checked={dataValidationSwitchChecked} onChange={(checked) => setDataValidationSwitchChecked(checked)} /></span>
            </div>
            <span>* Mandatory fields</span>
        </section>}
            {formTemplateData.map((formData, index) => {
                return (
                    <main className={`Registration_container ${index > 0 ? 'Registration_container-gap' : ''}`} key={index}>
                        <section className='Registration_subContainer'>
                            <div className='Registration_firstBox'>
                                <div className='Registration_heading'>
                                   {minimal? <h1>New Vendor</h1> : <h1>Add Vendor {parseInt(index) + 1}</h1>}
                                </div>
                                <div className='Registration_save'>
                                    {!_.get(saveStatus, index, false) ? (
                                        <Button type="link"  onClick={() => handleSave(index)}>Save</Button>
                                    ) : (
                                        <Button type="link"  className='Registration_saved'>Saved</Button>
                                    )}
                                </div>
                            </div>
                            <FormRegistration
                                formIndex={index}
                                formTemplateData={formData}
                                formStyle={{ padding: '24px' }}
                                onFieldChange={handleInputFieldChange}
                                setDisabledFields={handleDisabledFields}
                                setDisabledSections={handleDisabledSections}
                                disabledFields={disabledFields[index]}
                                disabledSections={disabledSections[index]}
                                formFormatLong={detailedFormSwitchChecked}
                                formDataValidation={isFormDataValidated}
                            />
                        </section>
                    </main>
                )
            }
            )}
           <div className='Registration_buttons'>
                <Button className='Registration_button' onClick={addNewForm}>+ Add new entry</Button>
                {!_.every(_.values(saveStatus)) && (_.values(saveStatus).length > 1) && (
                    <Button className='Registration_button' onClick={handleSaveAll}>Save All</Button>
                )}
                {showProcessingMessage && showLoader("Saving Entries")}
            </div>
            <RequestNudgeComponent
                type={'warning'}
                data={isWarningModalData}
                isVisible={isWarningModal}
                onClose={closeWarningModal} 
                handleNudgeSubmit={handleSubmitWarning}
            />
        </main>
    );
}
