import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RightOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { useSelector } from "react-redux";

import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FilterComponent from "../common/FilterComponent"
import { fetchData, postData } from '../../services/HomeService';
import ProductView from './ProductView';
import { getIcon } from '../../utils';

import styles from './style.module.css';

const _SEGMENT_LIST = ["economical", "value-added", "premium", "super-premium"];

export default function ProductListing({ selectedParent, selectedChild, AvatarColor, feeds }) {
    const observerRef = useRef();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    //TODO: Remove hard coded filters and implement in backend call
    const [selectedCategory, setSelectedCategory] = useState(new Set(_SEGMENT_LIST));

    const [productList, setProductList] = useState([]);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const userData = useSelector((state) => state.user);
    const [currPageInfo, setCurrPageInfo] = useState({ currTab: 0, currPage: 1 });

    const handleChangeTab = (newTabIndex) => {
        if (currPageInfo.currTab === newTabIndex) return;

        // Reset all states in a single batch to avoid race conditions
        setCurrPageInfo({ currPage: 1, currTab: newTabIndex });
        setProductList([]);
        setCanLoadMore(true);
    }
    const getProductList = useCallback(async () => {
        if (currPageInfo.currPage <= 0 || feeds.length === 0 || !canLoadMore || loading) return;

        setLoading(true);
        const currTab = currPageInfo.currTab;
        const res = await fetchData(`${feeds[currTab].api.path}&limit=10&page=${currPageInfo.currPage}`);

        if (!res) {
            setLoading(false);
            return;
        }

        if (res.data?.length < 10) {
            setCanLoadMore(false);
        }

        if (res.data?.length) {
            setProductList(prev => [...prev, ...res.data]);
            setCurrPageInfo(prev => ({ ...prev, currPage: prev.currPage + 1 }));
        }

        setLoading(false);
    }, [currPageInfo, feeds, canLoadMore, loading]);

    const handleReactProduct = async (product) => {
        const payload = {
            actions: product.isLiked ? 'dislike' : 'like',
            actor: userData.id
        }

        const res = await postData(`/products/react/${product._id}`, payload);
        if (res !== null) {
            const tmpProductList = productList.map((val) => {
                if (val._id === product._id) 
                    return {
                        ...val,
                        isLiked: !product.isLiked,
                        totalLikes: (product?.totalLikes || 0) + (product.isLiked ? -1 : 1)
                    }
                return val;
            });

            setProductList(tmpProductList);
        }
    }

    const handleSearch = () => {
        // search using input
    }

    const handleCategoryChange = (value) => {
        if (value === "all") {
            setSelectedCategory(new Set());
            return;
        } else if(value === "reset"){
            setSelectedCategory(new Set(_SEGMENT_LIST));
            return;
        }
        const newSelectedCategory = new Set(selectedCategory);
        newSelectedCategory.delete(value);
        setSelectedCategory(newSelectedCategory);
    };

    const handleObserve = useCallback((entries) => {
        const [entry] = entries;
        // Only trigger if actually intersecting and not during fast scrolls
        if (entry.isIntersecting && !loading && canLoadMore && entry.intersectionRatio >= 0.1) {
            getProductList();
        }
    }, [getProductList, loading, canLoadMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading) return;
            
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
            
            if (node && canLoadMore) {
                observerRef.current = new IntersectionObserver(handleObserve, { 
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.1
                });
                observerRef.current.observe(node);
            }
        },
        [loading, canLoadMore, handleObserve]
    );

    // Modify the useEffect to include currPageInfo.currTab as a dependency
    useEffect(() => {
        if (feeds.length > 0) {
            getProductList();
        }
    }, [feeds, currPageInfo.currTab]); // Add currPageInfo.currTab to dependencies

    return (
        <section className="Home_container">
            <section className="Home_firstContainer">
                <BreadcrumbComponent
                    selectedParent={selectedParent}
                    selectedChild={selectedChild}
                />
                <div
                    className="Home_heading"
                    style={{ justifyContent: 'space-between' }}
                >
                    <h1>{selectedChild}</h1>
                    <Button type="primary">
                        <ShoppingCartOutlined /> View Basket
                    </Button>
                </div>
            </section>

            <div className={styles.list}>
                <Row gutter={16}>
                    <Col lg={{ span: 18 }} md={{ span: 24 }}>
                        {
                            <FilterComponent
                                type={"product"}
                                categories={selectedCategory}
                                onCategoryChange={handleCategoryChange}
                            > </FilterComponent>
                        }
                    </Col>
                    <Col lg={{ span: 6 }} md={{ span: 24 }}>

                    </Col>
                </Row>
                <Row gutter={16}>
                    
                    <Col lg={{ span: 6 }} md={{ span: 24 }}>
                        {
                            feeds.map((val, i) => (
                                <div
                                    key={val.key}
                                    className={currPageInfo.currTab === i ? `${styles.tabs} ${styles.tabsActive}` : `${styles.tabs}`}
                                    onClick={() => handleChangeTab(i)}
                                >
                                    {getIcon(val.icon)}
                                    <span className={currPageInfo.currTab === i ? `${styles.tabText} ${styles.tabTextActive}` : `${styles.tabText}`}>
                                        {val.label}
                                    </span>
                                    <RightOutlined />
                                </div>
                            ))
                        }
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }}>
                        <Form onSubmitCapture={handleSearch}>
                            <Form.Item style={{ marginBottom: 10 }}>
                                <div className={styles.searchBox}>
                                    <Input
                                        placeholder='Search something'
                                        style={{ borderRadius: '28px' }}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <SearchOutlined
                                        className={styles.searchIcon}
                                        onClick={handleSearch}
                                    />
                                </div>
                            </Form.Item>
                        </Form>

                        {
                            productList.map((val, i) => (
                                <ProductView
                                    key={`productlist-${i}`}
                                    handleReactProduct={handleReactProduct}
                                    product={val}
                                    ref={i === productList.length - 1 ? lastPostElementRef : null}
                                />
                            ))
                        }
                        {loading && (
                            <div style={{ textAlign: 'center', marginTop: 20 }}>
                                <Spin />
                            </div>
                        )}
                        {!canLoadMore && (
                            <div style={{ textAlign: 'center', marginTop: 20 }}>
                                <p>No more products to display</p>
                            </div>
                        )}
                    </Col>
                    <Col lg={{ span: 6 }} md={{ span: 24 }}>

                    </Col>
                </Row>
            </div>
        </section>
    );
}
